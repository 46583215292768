
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

/* Utility classes */
.shadow {
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.bg-gray-300 {
  background: #f2f3f8;
}

.bg-white {
  background: #fff;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

/**
 * COMPONENTS
 */
.overlay {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.button-start {
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #dcdce0;
  border-radius: 10px;
  background: #fff;
  padding: 55px 45px 55px 55px;
  margin: 0 0 16px;
  transition: background 300ms;
}

.button-start:hover,
.button-start:active {
  background: #f2f3f8;
}

.button-start:hover .button-start-arrow,
.button-start:active .button-start-arrow {
  background: #484464;
}

.button-start:hover .button-start-arrow svg,
.button-start:active .button-start-arrow svg {
  transform: translateX(15px);
}

.button-start svg {
  display: block;
}

.button-start-flag {
  flex: none;
  width: 64px;
  margin-right: 42px;
}

.button-start-text {
  flex: auto;
  text-align: left;
  color: #231e45;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
}

.button-start-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 64px;
  background: #231e45;
  border-radius: 120px;
  transition: background 300ms;
}

.button-start-arrow svg {
  transition: transform 300ms;
}

.button-start-title {
  display: block;
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
}

.button {
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
}

.button-primary,
.button-secondary {
  border: none;
  min-width: 336px;
  padding: 8px 11px 8px 32px;
  height: 64px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  background: #231e45;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border-radius: 120px;
  justify-content: space-between;
}

.button-secondary {
  color: #231e45;
  background: #fff;
}

.button-secondary span,
.button-primary span {
  width: 86px;
  height: 100%;
  flex: none;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #231e45;
  background: #ffffff;
  border-radius: 120px;
}

.button-secondary span {
  background: transparent;
  border: 2px solid #231e45;
}

.button-primary:hover,
.button-primary:active {
  background: #484464;
}
.button-secondary:hover,
.button-secondary:active {
  background: #f2f3f8;
}

.button-large {
  height: 90px;
}

.button-filter {
  width: 200px;
  height: 200px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  padding: 0 10px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #231e45;
  text-align: center;
  border: 2px solid #fff;
}
.button-filter-sex {
  position: absolute;
  padding: 4px 12px;
  left: 8px;
  top: 8px;
  background: #6d6a83;
  border-radius: 24px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.button-filter-check {
  position: absolute;
  top: 7px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #dcdce0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
.button-filter-image {
  display: block;
  width: 108px;
  height: 108px;
  margin: 43px auto 8px;
}
.button-filter-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.button-filter.selected {
  border: 2px solid #f15a2a;
}
.button-filter.selected .button-filter-check {
  background: #f15a2a;
}
.game-filters-2 .button-filter.selected {
  border: 2px solid #7b61ff;
}
.game-filters-2 .button-filter.selected .button-filter-check {
  background: #7b61ff;
}

.tooltip {
  position: absolute;
  z-index: 10;
  width: 378px;
  padding: 16px 24px;
  color: #231e45;
  font-size: 18px;
  line-height: 26px;
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 6px rgba(24, 39, 75, 0.2));
}

.tooltip h2 {
  margin: 0 0 6px;
  font-size: 22px;
  font-weight: 700;
}

.tooltip:after {
  content: "";
  position: absolute;
  border: 11px solid;
  border-color: transparent transparent transparent #fff;
  right: -22px;
  top: calc(50% - 11px);
}

.tooltip-bottom {
  width: 407px;
}

.tooltip-bottom:after {
  content: "";
  position: absolute;
  border: 11px solid;
  border-color: #fff transparent transparent;
  bottom: -22px;
  left: 46px;
  right: auto;
  top: auto;
}

.button-help {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  color: #231e45;
  border: 2px solid #dcdce0;
  margin-left: auto;
}
.button-help:hover,
.button-help:active {
  background: #f2f3f8;
}

.button-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  color: #fff;
  background: #231e45;
  margin-left: 9px;
}
.button-home:hover,
.button-home:active {
  background: #484464;
}
.button-camera {
  position: absolute;
  background: #dadfe8;
  z-index: 5;
  right: 64px;
  bottom: 40px;
  width: 188px;
  height: 188px;
  border-radius: 188px;
  border: 5px solid #fff;
}
.button-camera-sample {
  opacity: 0.4;
}
.button-camera-inner-shadow {
  position: absolute;
  width: 178px;
  height: 178px;
  left: 0;
  top: 0;
  border-radius: 180px;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.16);
  z-index: 5;
}

.button-camera-inner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
  left: 10px;
  top: 10px;
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background: #fff;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
    0px 10px 32px -4px rgba(24, 39, 75, 0.1);
}

.button-camera-camera {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  left: 19px;
  top: 19px;
  width: 122px;
  height: 122px;
  border-radius: 122px;
  color: #fff;
  background: #f15a2a;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
}
.button-camera-circle {
  transform: rotate(-90deg);
  stroke-dashoffset: 527;
  /*
  1 - 422
  2 - 316
  3 - 211
  4 - 105
  5 - 0
  */
}
.button-camera-counter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  left: 19px;
  top: 19px;
  width: 122px;
  height: 122px;
  font-weight: 500;
  font-size: 80px;
  line-height: 97px;
  color: #231e45;
}
.button-camera-take {
  width: 126px;
  height: 126px;
  border-radius: 126px;
  border: 19px solid #f8ac93;
  background: #f5835f;
}

.keyboard {
  padding: 48px 0 41px;
}
.keyboard-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
}
.keyboard-button {
  width: 78px;
  height: 63px;
  color: #231e45;
  border: 2px solid #231e45;
  background: #fff;
  margin: 0 3px;
  padding: 0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}
.keyboard-button:hover,
.keyboard-button:active {
  color: #fff;
  background: #231e45;
}

/**
 * VIEWS
 */

/* Home view */
.home {
  display: flex;
}

.home-left {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-right {
  width: 1078px;
  flex: none;
  padding: 140px 50px 0 50px;
  overflow-y: auto;
}

.home-logo {
  margin-bottom: 222px;
}

.instructions {
  display: flex;
}

.instructions-left {
  flex: auto;
  position: relative;
  border-top: 16px solid #fff;
  border-left: 16px solid #fff;
  border-bottom: 16px solid #fff;
  background: #fff;
}
.instructions-left-inner {
  padding: 117px 0 0 48px;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background: #f2f3f8;
}

.instructions-right {
  position: relative;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 476px;
  padding: 0 0 105px 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 75.51%
  );
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.instructions-text {
  max-width: 652px;
  color: #231e45;
  font-size: 18px;
  line-height: 22px;
}
.instructions-text h1 {
  margin: 0;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
}
.instructions-text p {
  margin: 24px 0;
}
.instructions-text h2 {
  margin: 40px 0 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.instructions-text ul {
  margin: 24px 0;
  padding: 0 0 0 27px;
}

.instructions-text li {
  margin: 0 0 24px;
}

.instructions-images {
  left: 48px;
  bottom: 225px;
}

.instructions-photo {
  right: 296px;
  bottom: 74px;
}

.instructions-filters {
  right: 44px;
  top: 456px;
}

.instructions-players {
  right: 44px;
  top: 80px;
}

.close {
  display: flex;
}

.close-left {
  flex: auto;
  position: relative;
  border-top: 16px solid #fff;
  border-left: 16px solid #fff;
  border-bottom: 16px solid #fff;
  background: #fff;
}
.close-left-inner {
  padding: 0;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background: #f2f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-left-text h1 {
  text-align: center;
  margin: 0 10px 68px;
  color: #231e45;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
}
.close-left-text .button {
  margin: 0 10px;
}

.close-right {
  position: relative;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 476px;
  padding: 0 0 105px 0;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.preview {
  display: flex;
}

.preview-left {
  flex: auto;
  position: relative;
  border-top: 16px solid #fff;
  border-left: 16px solid #fff;
  border-bottom: 16px solid #fff;
  background: #fff;
}
.preview-left-inner {
  padding: 172px 0 0;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background: #f2f3f8;
  text-align: center;
}
.preview-left-text {
  max-width: 652px;
  margin: 0 auto;
  text-align: center;
  color: #231e45;
  font-size: 18px;
  line-height: 22px;
}
.preview-left-text h1 {
  text-align: center;
  margin: 0 0 24px;
  color: #231e45;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
}
.preview-left .button {
  margin: 0 10px;
}

.preview-right {
  position: relative;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 476px;
  padding: 0 0 105px 0;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.preview-images {
  margin: 68px auto 64px;
  padding: 0;
  list-style: none;
  left: 48px;
  bottom: 40px;
  display: flex;
  justify-content: center;
}
.preview-images li {
  position: relative;
  margin: 0 8px;
  padding: 14px 15px 36px 15px;
  background: #fff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
}
.preview-image-delete {
  position: absolute;
  right: -3px;
  top: -21px;
  border: none;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50px;
  color: #fff;
  background: #231e45;
}
.preview-image-delete.inactive {
  background: #f15a2a;
}
.preview-image-delete:hover,
.preview-image-delete:active {
  background: #484464;
}
.preview-images-image {
  position: relative;
  width: 270px;
  height: 270px;
  background: #ffffff;
  border-radius: 2px;
  color: #231e45;
}

.preview-images-image svg {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.4;
  transform: translateX(-50%) translateY(-50%);
}

.preview-images-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game {
  display: flex;
  height: 100vh;
}

.game-left {
  position: relative;
  flex: auto;
  overflow: hidden;
  border-top: 16px solid #fff;
  border-left: 16px solid #fff;
  border-bottom: 16px solid #fff;
  background: #fff;
}

.game-right {
  display: flex;
  flex-direction: column;
  width: 476px;
  flex: none;
  background: #fff;
  border-bottom: 16px solid transparent;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
}

.game-canvas {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}

.game-filters-header {
  display: flex;
  align-items: center;
  flex: none;
  padding: 0 10px 0 24px;
  height: 109px;
  background: #fff;
}

.game-filters {
  display: flex;
  flex: auto;
  padding: 10px 6px 0 6px;
  background: #f2f3f8;
}
.game-filters-player {
  width: 50%;
  margin: 0 4px;
  background: #ffffff;
  border-radius: 10px;
}
.game-selected-filter {
  height: 174px;
  flex: none;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #231e45;
  padding: 27px 0 0;
}
.game-selected-filter-image-1 {
  width: 91px;
  height: 91px;
  border-radius: 100px;
  margin: 0 auto 10px;
  border: 2px solid #f15a2a;
}
.game-selected-filter-image-2 {
  width: 91px;
  height: 91px;
  border-radius: 100px;
  margin: 0 auto 10px;
  border: 2px solid #7b61ff;
}

.game-selected-filter-image-1 img,
.game-selected-filter-image-2 img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.game-filters-1,
.game-filters-2 {
  display: block;
  height: calc(100vh - 109px - 10px - 174px - 16px);
  margin: 0;
  padding: 20px 8px 4px;
  list-style: none;
  overflow-y: auto;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.game-filters-1 li,
.game-filters-2 li {
  margin-bottom: 16px;
}
.game-filters-1::-webkit-scrollbar,
.game-filters-2::-webkit-scrollbar {
  width: 8px;
}

.game-filters-1::-webkit-scrollbar-track,
.game-filters-2::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 0 10px 10px 0;
}

.game-filters-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #f15a2a;
}
.game-filters-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: #7b61ff;
}

.game-filters-1 {
  background: rgba(241, 90, 42, 0.2);
}
.game-filters-2 {
  background: rgba(123, 97, 255, 0.2);
}

.game-filter-loading {
  position: absolute;
  pointer-events: none;
  z-index: 7;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(236, 238, 244, 0.9);
  backdrop-filter: blur(80px);
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #231e45;
  border-radius: 8px 0px 0px 8px;
  transition: opacity 300ms;
}
.game-filter-loading.is-visible {
  opacity: 1;
}

.game-filter-loading span {
  display: block;
  width: 58px;
  margin: 40px auto 0;
}

.game-photo-overlay {
  position: absolute;
  pointer-events: none;
  z-index: 5;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #F8F8FC;
  opacity: 0.4;
  border-radius: 8px 0px 0px 8px;
  transition: opacity 100ms;
}
.game-photo-overlay.is-hidden {
  opacity: 0;
}

.game-images-instructions {
  left: 44px;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;
}

.game-images-instructions.is-visible {
  transform: translateY(-220px);
}

.game-images-instructions:after {
  content: none;
}
.game-images-instructions-icon {
  border: none;
  z-index: 5;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50px;
  color: #fff;
  background: #231e45;
  margin: 5px 0 0 2px;
}

.game-images {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 48px;
  bottom: 40px;
  display: flex;
}
.game-images-sample {
  opacity: 0.8;
}
.game-images li {
  position: relative;
  margin-right: 8px;
  padding: 6px 5px 15px 5px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
}
.game-image-delete {
  position: absolute;
  right: -3px;
  top: -21px;
  border: none;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50px;
  color: #fff;
  background: #231e45;
}
.game-image-delete:hover,
.game-image-delete:active {
  background: #484464;
}
.game-images-image {
  position: relative;
  width: 120px;
  height: 120px;
  background: #ffffff;
  border-radius: 2px;
  color: #231e45;
}

.game-images-image svg {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.4;
  transform: translateX(-50%) translateY(-50%);
}

.game-images-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.email {
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow-y: auto;
}

.email-top {
  flex: none;
  position: relative;
  padding: 97px 0 0;
  height: 470px;
  background: #f2f3f8;
}
.email-bottom {
  flex: auto;
  position: relative;
  text-align: center;
  padding: 51px 0 0;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
}
.email-logo {
  position: absolute;
  left: 24px;
  top: 28px;
}
.email .button-home {
  position: absolute;
  right: 25px;
  top: 25px;
}

.email-images {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  left: 48px;
  bottom: 40px;
  display: flex;
  justify-content: center;
}
.email-images li {
  position: relative;
  margin: 0 8px;
  padding: 14px 15px 36px 15px;
  background: #fff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 4px;
}
.email-images-image {
  position: relative;
  width: 270px;
  height: 270px;
  background: #ffffff;
  border-radius: 2px;
  color: #231e45;
}
.email-images-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.email-input {
  position: relative;
  margin: 0 auto;
  padding: 0 32px;
  width: 570px;
  height: 64px;
  border: 2px solid #231e45;
  border-radius: 120px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  color: #231e45;
}
.email-input .button-backspace {
  margin: 0 0 0 auto;
  padding: 0;
  background: none;
  border: none;
}
.email-sending {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 7;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #231e45;
  border-radius: 8px 0px 0px 8px;
  transition: opacity 300ms;
}
.email-sending.is-visible {
  opacity: 1;
}

.email-sending span {
  display: block;
  width: 58px;
  margin: 40px auto 0;
}
.email-sent {
  padding: 100px 0 0;
  text-align: center;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #231e45;
}
.email-sent svg {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
.email-closing {
  padding: 139px 0 0;
  text-align: center;
  color: #231e45;
  font-size: 16px;
  line-height: 19px;
}
.email-closing .button-camera {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  margin: 24px auto 0;
}
